import { ThemeProvider } from 'styled-components';
import darktheme from 'shared/styles/theme';
import { OAuthProvider } from 'modules/authentication/oauth.context';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider as ThemeContextProvider } from 'modules/theme/theme.context';
import { routes } from './router/routes'

const router = createBrowserRouter(routes);

function App() {

  return (
    <ThemeProvider theme={darktheme}>
      <ThemeContextProvider>
          <OAuthProvider>
            <div style={{height: 'inherit'}}>
              <RouterProvider router={router} />
            </div>
          </OAuthProvider>
      </ThemeContextProvider>
    </ThemeProvider>
  )
}

export default App
