import { Typography } from 'antd';
import Button from 'shared/components/ui/button.component';
import styled from 'styled-components';

export const TimerContainer = styled.div`
  height: 520px;
  width: 323px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DescriptionText = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  padding-top: 50px;
  color: #ffffffe3;
`;

export const Timer = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  color: ${({ theme }) => theme.colors.primaryColorBase};
  font-size: 48px;

  position: relative;
  top: 60px;

  display: flex;
  justify-content: center;
  width: 448px;
`;

export const CancelButton = styled(Button)`
  background-color: rgba(255, 82, 71, 0.13) !important;
  color: #ff5247 !important;
  height: 44px;
  margin: 0px 33px 0;
  width: 334px !important;
  top: -70px;
  &:hover {
    background-color: rgba(255, 82, 71, 0.13) !important;
    color: #ff5247 !important;
  }
  &:focus {
    background-color: rgba(255, 82, 71, 0.13) !important;
    color: #ff5247 !important;
  }
`;

export const FooterText = styled(Typography.Text)`
  position: relative;
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  top: -80px;
  padding: 32px 0 40px;
  color: #ffffff8c;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
`;
