const DISPOSITION_EVENT = 'disposition';
export enum DispositionNames {
  Normal = 'Normal',
  Busy = 'Busy',
  NoAnswer = 'NoAnswer',
  Illegit = 'Illegit',
  OthLang = 'OthLang',
  SilentAnsMachine = 'SilentAnsMachine',
  SilentMidCall = 'SilentMidCall',
  SilentNoTimer = 'SilentNoTimer',
  SilentTimerRunning = 'SilentTimerRunning',
  SpecialTone = 'SpecialTone',
}

export const useDispositions = (send: (data: string) => void) => {
  const sendDisposition = (callId: string, disposition: DispositionNames) => {
    const msgToGateway = {
      event: DISPOSITION_EVENT,
      payload: {
        call_id: callId,
        disposition,
      },
    };

    send(JSON.stringify(msgToGateway));
  };
  return {
    sendDisposition,
  };
};
