import { useContext, useEffect } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import BottomControls from 'modules/ipcts-call-session/components/bottom-controls/bottom-controls.component';
import { NetworkStatus } from 'modules/ipcts-call-session/pfc-screen/components/network-status.component';
import PfcSettings from 'modules/ipcts-call-session/pfc-screen/components/pfc-settings.component';
import * as Styled from './AgentLobby.styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { CaptionShard, GateWaySockets, TranscriptMsg } from 'shared/hooks/axon/gateway.types';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { Editor, EditorProvider } from 'shared/components/ui/editor';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';
import { useUpdateTranscript } from 'shared/hooks/axon/ipcts-gw/use-update-transcript.hook';
import testShards from 'modules/landing-page/context/test-caption-shards.json'
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

const LOREM =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur';

const AgentLobby = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { updateUserState } = useUserState();
  const { updateTranscript } = useUpdateTranscript();
  const captions = useSelector((state: RootState) => state.captions.value);
  

  const parsedLorem = LOREM.split(' ').map((word: string, index: number) => {
    const shard: CaptionShard = {
      shardId: index,
      shardText: word,
      alternatives: [],
      shardWords: [
        {
          wordAlternatives: [],
          wordId: index + 100,
          wordText: zeroWidthJoiner.space + ' ' + word,
          shardId: index,
        },
      ],
      editable: false,
      userEditing: false,
      formattedText: word,
    };

    return shard;
  });

  const {
    cardCaptionCallRef,
    handleStartListening,
    captionAreaFontSize,
    captionAreaLineHeight,
    captionAreaFontFamily,
    reset,
  } = useContext(IPCTSSessionContext);

  const handdleStartTakingCalls = async () => {
    await handleStartListening(GateWaySockets.captionerSocket);
    reset();
    navigate('/agent-queue', { state: { from: location } });
  };
  useEffect(() => {
    updateUserState(UserStates.IN_LOBBY);

    // can use updateTranscript below to load lobby editor with test shards
    // change editor to be like:
    // <Editor value={captions} editable={true} />

    // updateTranscript({
    //   shards: testShards,
    //   source: 'FUSER'
    // } as unknown as TranscriptMsg);
  
  }, []);

  return (
    <Styled.PFCPageContainer>
      <Styled.LeftContainer>
        <Styled.CardCaptionCall
          ref={cardCaptionCallRef}
          className="vertical-default-scroll"
          isDisabled={false}
          fontSize={captionAreaFontSize}
          lineHeight={captionAreaLineHeight}
          fontFamily={captionAreaFontFamily}
        >
          <div className="editor-provider-wrapper">
            <EditorProvider>
              <Editor value={parsedLorem} editable={false} />
            </EditorProvider>
          </div>
        </Styled.CardCaptionCall>
        <BottomControls />
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <PfcSettings />
        <Styled.ButtonContainer>
          <div>
            <NetworkStatus />
          </div>
          <Styled.StartButton id="nextButton" onClick={handdleStartTakingCalls}>
            Start taking calls
          </Styled.StartButton>
        </Styled.ButtonContainer>
      </Styled.RightContainer>
    </Styled.PFCPageContainer>
  );
};
export default AgentLobby;
