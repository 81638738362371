import { useContext, useState, useEffect, useRef } from 'react';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { Navigate, useLocation } from 'react-router-dom';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';
import { UserStates } from 'state/userSessionState/userSessionStateSlice';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';
import CallControls from 'modules/ipcts-call-session/components/controls/call-controls.component';
import { Editor, EditorProvider } from 'shared/components/ui/editor';
import * as Styled from 'components/AgentSession/AgentSession.styled';
import Chronometer from 'components/Chronometer';

const AgentSession = () => {
  const [isHandoff, setIsHandoff] = useState(false);
  const captions = useSelector((state: RootState) => state.captions.value);
  const { updateUserState } = useUserState();
  const location = useLocation();
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    isCallActive,
    setIsCallActive,
    dispositionSelected,
    setDispositionSelected,
    setSilentDispositionSelected,
  } = useContext(LandingPageContext);

  const {
    isActiveSession,
    isTokenReady,
    cardCaptionCallRef,
    reset,
    addCustomCaption,
    captionAreaFontSize,
    captionAreaLineHeight,
    captionAreaFontFamily,
    sendCallDisposition,
    sendSilentDisposition,
    sendTerminateCall,
  } = useContext(IPCTSSessionContext);

  const dispositionCallEnd = () => {
    reset();
    setIsCallActive(false);
  };

  useEffect(() => {
    updateUserState(UserStates.IN_CALL);
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [captions]);  

  const handoffCallEnd = () => {
    dispositionCallEnd();
    setIsHandoff(true);
  };
  if (!isCallActive) {
    return <Navigate to="/agent-queue" state={ { from: location } }/>;
  }

  return (
    <Styled.DataContainer>
      <Styled.LeftContainer>
        <Styled.CardSessionContainer ref={scrollContainerRef}>
          <Styled.CardCaptionCall
            ref={cardCaptionCallRef}
            className="vertical-default-scroll"
            isDisabled={isActiveSession && !isTokenReady}
            fontSize={captionAreaFontSize}
            lineHeight={captionAreaLineHeight}
            fontFamily={captionAreaFontFamily}
          >
            <div className="editor-provider-wrapper">
              <EditorProvider>
                <Editor value={captions} />
              </EditorProvider>
            </div>
          </Styled.CardCaptionCall>
        </Styled.CardSessionContainer>
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <Styled.ChronoContainer>
          <Chronometer
            id={'callLengthTimer'}
            timeWrapperProps={{ fontWeight: '600', width: '100%' }}
            inQueue
          />
        </Styled.ChronoContainer>
        <CallControls
          dispositionCallEnd={dispositionCallEnd}
          handoffCallEnd={handoffCallEnd}
          addCustomCaption={addCustomCaption}
          dispositionSelected={dispositionSelected}
          setDispositionSelected={setDispositionSelected}
          setSilentDispositionSelected={setSilentDispositionSelected}
          sendCallDisposition={sendCallDisposition}
          sendSilentDisposition={sendSilentDisposition}
          sendTerminateCall={sendTerminateCall}
        />
      </Styled.RightContainer>
    </Styled.DataContainer>
  );
};

export default AgentSession;
