import Background from 'assets/icons/background.svg';
import * as Styled from 'modules/download/download.style';
import { config } from 'shared/config/cts.config';

const URL = config.REACT_APP_DOWNLOAD_URL;

export const Download = () => {
  const environmentHosts = {
    'capzonedev': {
      appName: 'CTS-Web-Dev',
      adminAppName: 'captioner-admin-dev',
    },
    'dev-cts': {
      appName: 'CTS-Web-Dev',
      adminAppName: 'captioner-admin-dev',
    },
    'qa-cts': {
      appName: 'CTS-Web-Qa',
      adminAppName: 'captioner-admin-qa',
    },
    'uat-cts': {
      appName: 'CTS-Web-Uat',
      adminAppName: 'captioner-admin-uat',
    },
    'cts': {
      appName: 'CTS-Web',
      adminAppName: 'captioner-admin',
    },
  };

  const isWindows = navigator?.userAgent.includes('Windows');
  const host = location.host;
  const subdomain = host.split('.')[0];
  const FILE_EXTENSION = isWindows ? '.exe' : '.dmg';
  const FOLDER = isWindows ? 'WIN' : 'MAC';
  const OS = isWindows ? 'Windows' : 'MacOs';
  const captionerHref = `${URL}/CAPTIONER/${FOLDER}/${environmentHosts[subdomain].appName}${FILE_EXTENSION}`;
  const adminHref = `${URL}/ADMIN/${FOLDER}/${environmentHosts[subdomain].adminAppName}${FILE_EXTENSION}`;

  return (
    <>
      <Styled.BackgroundContainer>
        <Styled.Background src={Background}> </Styled.Background>
        <Styled.MainDiv>
          <Styled.Title>
            <h5>IP Captioned Telephone Service</h5>
            <img src="icons/sorenson-logo-152x152.png" width={80} />
          </Styled.Title>
        </Styled.MainDiv>
        <Styled.ProductSection>
          <Styled.ButtonContainer>
            <Styled.Button href={captionerHref}>
              Download Captioner for {OS}
            </Styled.Button>
          </Styled.ButtonContainer>
          <Styled.ButtonContainer>
            <Styled.Button href={adminHref}>
              Download Admin for {OS}
            </Styled.Button>
          </Styled.ButtonContainer>
        </Styled.ProductSection>
      </Styled.BackgroundContainer>
    </>
  );
};
