export enum ReasonCodes {
  NonBill = 'NonBill',
  StvCancl = 'StvCancl',
  Abandon = 'Abandon',
  Cancel = 'Cancel',
  NoAnswer = 'NoAnswer',
  Test = 'Test',
  Normal = 'Normal',
  Silent = 'Silent',
  SilentMidCall = 'SilentMidCall',
  SilentAnsMachine = 'SilentAnsMachine',
  Busy = 'Busy',
  FaxTone = 'FaxTone',
  Illegit = 'Illegit',
  SitTone = 'SitTone',
  OthLang = 'OthLang',
}