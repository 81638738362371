import Dispositions from 'components/Dispositions';
import * as StyledControls from 'modules/ipcts-call-session/components/controls/call-controls.styled';
import AnsweringMachineDispositions from 'modules/ipcts-call-session/components/controls/call-dispositions-answering-machine.component';
import ThreePartyMode from 'modules/ipcts-call-session/components/controls/call-dispositions-three-party-mode.component';
import Timer from 'modules/ipcts-call-session/components/controls/timer.component';
import * as React from 'react';
import { ECallModes } from 'shared/datasources/call-detail-record-service/entities.dto';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes';

interface ICallDispositionsMenuProps {
  dispositionCallEnd: () => void;
  sendCallDisposition: (disposition: DispositionNames) => void;
  sendSilentDisposition: () => DispositionNames;
  sendTerminateCall: (reason: ReasonCodes) => void;
}

const CallDispositionsMenu = ({
  dispositionCallEnd,
  sendCallDisposition,
  sendSilentDisposition,
  sendTerminateCall
}: ICallDispositionsMenuProps) => {
  const [showTimer, setShowTimer] = React.useState(false);
  const [timerSeconds, setTimerSeconds] = React.useState(0);
  const [callMode, setCallMode] = React.useState(ECallModes.TwoParty);

  (window as any).changeDisposition = (value: number = 0) => {
    if (value === 2) {
      setCallMode(ECallModes.TwoParty);
      return;
    }
    if (value === 3) {
      setCallMode(ECallModes.ThirdParty);
      return;
    }
    setCallMode(ECallModes.AnsweringMachine);
  };

  // export enum CallState {
  //   TwoOnCall = 'TwoOnCall',
  //   OneOnCallEnd = 'OneOnCallEnd',
  //   NoneOnCall = 'NoneOnCall',
  //   OneOnCall = 'OneOnCall',
  // }

  // const handleSilentOptions = (selected: string) => {
  //   const flows = {
  //     [ESilentCallDispositions.ClearEnding.toString()]: () => {
  //       setDispositionSelected(ETerminationReasonCode.Silent);
  //       setSilentDispositionSelected(ESilentCallDispositions.ClearEnding);
  //       setTimerSeconds(60);
  //       setShowTimer(true);
  //     },
  //     [ESilentCallDispositions.MidCall.toString()]: () => {
  //       setDispositionSelected(ETerminationReasonCode.Silent);
  //       setSilentDispositionSelected(ESilentCallDispositions.MidCall);
  //       setTimerSeconds(300);
  //       setShowTimer(true);
  //     },
  //   };
  //   const result = flows[selected];
  //   if (!result) {
  //     return;
  //   }
  //   result();
  // };

  const showDispositionForCallMode = () => {
    if (callMode === ECallModes.AnsweringMachine) {
      return <AnsweringMachineDispositions />;
    }
    if (callMode === ECallModes.ThirdParty) {
      return (
        <ThreePartyMode
          setTimerSeconds={setTimerSeconds}
          setShowTimer={setShowTimer}
        />
      );
    }
    return (
      <Dispositions 
        sendCallDisposition={sendCallDisposition}
        sendSilentDisposition={sendSilentDisposition}        
        sendTerminateCall={sendTerminateCall}
      />
    );
  };

  return (
    <StyledControls.CallControlsBody>
      {showTimer ? (
        <Timer
          seconds={timerSeconds}
          setShowTimer={setShowTimer}
          dispositionCallEnd={dispositionCallEnd}
        />
      ) : (
        showDispositionForCallMode()
      )}
    </StyledControls.CallControlsBody>
  );
};

export default CallDispositionsMenu;
