import Loader from 'modules/ipcts-call-session/components/controls/loader.component';
import * as React from 'react';

import * as Styled from './timer.styled';

interface ITimerProps {
  seconds: number;
  setShowTimer?: React.Dispatch<React.SetStateAction<boolean>>;
  timerExpiration?: () => void;
  canceledTimer?: () => void;
  timerExpired?: () => void;
  dispositionCallEnd?: () => void;
  footerText?: string;
}

const Timer = ({
  seconds,
  setShowTimer,
  timerExpired,
  canceledTimer,
  dispositionCallEnd,
  footerText,
}: ITimerProps) => {
  const [timerSeconds, setTimerSeconds] = React.useState(seconds);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTimerSeconds(timerSeconds > 0 ? timerSeconds - 1 : timerSeconds);
    }, 1000);

    if (timerSeconds <= 0) {
      dispositionCallEnd && dispositionCallEnd();
      timerExpired && timerExpired();
      setShowTimer && setShowTimer(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timerSeconds]);

  const timerFormat = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    return `${minutes}:${formattedSeconds}`;
  };

  const wordFormat = (seconds: number) => {
    if (seconds <= 59) {
      return 'seconds';
    } else if (seconds === 60) {
      return 'minute';
    } else {
      return 'minutes';
    }
  };

  const cancelTimer = () => {
    canceledTimer && canceledTimer();
    dispositionCallEnd && dispositionCallEnd();
    setShowTimer && setShowTimer(false);
  };

  return (
    <Styled.TimerContainer>
      <Styled.DescriptionText id="dispositionTimerDescription">
        {setShowTimer &&
          `Call will end in ${timerFormat(timerSeconds)} ${wordFormat(
            timerSeconds
          )}.
        <br />
        Press Cancel if audio begins`}
      </Styled.DescriptionText>
      <br />
      <Styled.Timer id="timer">{timerFormat(timerSeconds)}</Styled.Timer>
      <br />
      <Loader durationInSeconds={timerSeconds} type="big-loader" />
      {footerText && <Styled.FooterText>{footerText}</Styled.FooterText>}
      <Styled.CancelButton id="cancelTimerButton" onClick={cancelTimer}>
        Cancel Timer
      </Styled.CancelButton>
    </Styled.TimerContainer>
  );
};

export default Timer;
