import NO_ANSWER from 'assets/icons/no-answer-icon.svg';
import * as Styled from './Dispositions.styled';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';

interface INoAnswerDisposition {
  setSelectedDisposition: React.Dispatch<
    React.SetStateAction<DispositionNames>
  >;
  cancelDisposition: () => void;
}

const NoAnswerDisposition = ({
  setSelectedDisposition,
  cancelDisposition,
}: INoAnswerDisposition) => {
  return (
    <Styled.NoAnswerContainer>
      <Styled.NoAnswerContent>
        <Styled.DispositionLabelContainer>
          <Styled.SVGIcon src={NO_ANSWER} />
          <Styled.ButtonText>No Answer</Styled.ButtonText>
        </Styled.DispositionLabelContainer>
        <Styled.TextOnly>Wait for Disconnect</Styled.TextOnly>
      </Styled.NoAnswerContent>
      <Styled.CancelButton
        onClick={() => {
          cancelDisposition();
          setSelectedDisposition(DispositionNames.Normal);
        }}
      >
        Cancel
      </Styled.CancelButton>
    </Styled.NoAnswerContainer>
  );
};

export default NoAnswerDisposition;
