import {
  IPCTSSessionContextData,
  Caption,
} from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.types';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import * as React from 'react';
import { GateWaySockets } from 'shared/hooks/axon/gateway.types';
import useGateWay from 'shared/hooks/axon/ipcts-gw/use-gateway.hook';
import { AffectedShards } from 'shared/hooks/axon/corrections/corrections.types';
import logger from 'services/logger';
import { DispositionNames } from 'shared/hooks/axon/dispositions/use-dispositions.hook';
import { ReasonCodes } from 'shared/hooks/axon/ipcts-gw/termination-and-non-bill-reason-codes'

export type Transcription = {
  text: string;
  shardId: number;
  alternatives: string[];
};

export type SessionType = {
  id: number;
  token: string;
  title: string;
  description: string;
  enableTranscript: boolean;
  sessionStart: string;
  sessionStop: string;
  createdDt: string;
  sessionPassword: string;
};

export const IPCTSSessionContext = React.createContext<IPCTSSessionContextData>(
  {
    text: [],
    handleStopListening: () => Promise.resolve(),
    handleStartListening: () => {},
    cardCaptionCallRef: null,
    isActiveSession: false,
    isTokenReady: false,
    customWord: [],
    setCustomWord: () => {},
    reset: () => {},
    addCustomCaption: () => {},
    correctionsCounter: 0,
    captionAreaFontSize: 24,
    setCaptionAreaFontSize: () => {},
    captionAreaLineHeight: 1,
    setCaptionAreaLineHeight: () => {},
    captionAreaFontFamily: '',
    setCaptionAreaFontFamily: () => {},
    accent: '',
    setAccent: () => {},
    isMuted: false,
    setIsMuted: () => {},
    sendShardCorrection: (affectedShards: AffectedShards[]) => {},
    sendShardInsertion: () => {},
    sendShardSubstitution: () => {},
    activeGateway: '',
    setActiveGateway: () => {},
    sendNotAcceptingCalls: () => {},
    sendCallDisposition: (disposition: DispositionNames) => {},
    sendSilentDisposition: () => DispositionNames.Normal,
    sendTerminateCall: (reason: ReasonCodes) => {},
  }
);

const IPCTSSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const { agentId, callId } = React.useContext(LandingPageContext);
  const cardCaptionCallRef = React.useRef<HTMLDivElement | null>(null);

  const elements = React.useRef<Caption[]>([]);
  const [isActiveSession, setIsActiveSession] = React.useState(false);
  const [isTokenReady, setIsTokenReady] = React.useState(false);
  const [accent, setAccent] = React.useState('');
  const [activeGateway, setActiveGateway] = React.useState('');

  const [isMuted, setIsMuted] = React.useState(false);
  const [customWord, setCustomWord] = React.useState<any>([]);
  const correctionsCounterRef = React.useRef(0);
  const [captionAreaFontSize, setCaptionAreaFontSize] = React.useState(24);
  const [captionAreaLineHeight, setCaptionAreaLineHeight] = React.useState(32);
  const [captionAreaFontFamily, setCaptionAreaFontFamily] = React.useState('');

  const {
    openConnection,
    closeConnections,
    sendCallDisposition,
    sendCorrection,
    sendInsertion,
    sendSubstitution,
    sendNotAcceptingCalls,
    sendTerminateCall,
    sendSilentDisposition,
  } = useGateWay();

  const sendShardCorrection = (affectedShards: AffectedShards[]) => {
    sendCorrection(affectedShards);
  };

  const sendShardInsertion = (
    priorWordId: number,
    priorWord: string,
    newText: string
  ) => {
    if (activeGateway === GateWaySockets.captionerSocket) {
      sendInsertion(priorWordId, priorWord, newText);
    }
  };

  const sendShardSubstitution = (
    wordId: number,
    replaceText: string,
    newText: string
  ) => {
    if (activeGateway === GateWaySockets.captionerSocket) {
      sendSubstitution(wordId, replaceText, newText);
    }
  };

  const reset = () => {
    setIsMuted(false);
    elements.current = [];
  };

  const handleStartListening = async (socket: GateWaySockets) => {
    logger.info({
      callId,
      methodName: handleStartListening.name,
      parameters: { socket },
    });
    if (socket === GateWaySockets.captionerSocket) {
      openConnection();
      setIsTokenReady(true);
    }
  };

  const handleStopListening = React.useCallback(async () => {
    closeConnections();
    return Promise.resolve();
  }, [setIsActiveSession]);

  const addCustomCaption = (text: string, editable: boolean = true) => {};

  React.useEffect(() => {
    const unloadCallback = (e: BeforeUnloadEvent) => {
      const event = e;
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <IPCTSSessionContext.Provider
      value={{
        text: [],
        isActiveSession,
        isTokenReady,
        cardCaptionCallRef,
        customWord,
        setCustomWord,
        handleStopListening,
        handleStartListening,
        reset,
        addCustomCaption,
        correctionsCounter: correctionsCounterRef.current,
        captionAreaFontSize,
        setCaptionAreaFontSize,
        captionAreaLineHeight,
        setCaptionAreaLineHeight,
        captionAreaFontFamily,
        setCaptionAreaFontFamily,
        accent,
        setAccent,
        isMuted,
        setIsMuted,
        sendShardCorrection,
        sendShardInsertion,
        sendShardSubstitution,
        activeGateway,
        setActiveGateway,
        sendNotAcceptingCalls,
        sendCallDisposition,
        sendSilentDisposition,
        sendTerminateCall,
      }}
    >
      {children}
    </IPCTSSessionContext.Provider>
  );
};

export default IPCTSSessionProvider;
